import { useEffect, useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import {
  navbarStyle,
  navbarStyle2,
  navbarTitleStyle,
  TypographyStyle,
} from "../Theme/styles";

import logo from "../../logo.png";

const Navbar = () => {
  const [id, setId] = useState(0);
  const admin = sessionStorage.getItem("admin") || "";
  const getHrefId = (location) => {
    const href = location.toString();
    if (href.includes("about")) setId(2);
    else if (href.includes("what")) setId(3);
    else if (href.includes("packages")) setId(4);
    else if (href.includes("student")) setId(5);
    else if (href.includes("contact")) setId(6);
    else if (href.includes("home")) setId(1);
    else setId(0);
  };

  const logoutAdmin = () => {
    sessionStorage.removeItem("admin");
    window.location.replace("/login");
  };
  useEffect(() => {
    const location = window.location.href;
    getHrefId(location);
  }, [admin]);
  return (
    <>
      <Grid container mb={2}>
        <Grid
          sx={{
            ...navbarStyle2,
            height: 32,
            mt: "16px",
            backgroundColor: "#4CDBC4",
          }}
          item
          display="flex"
          alignItems="center"
          justifyContent="center"
          md={2}
          mb={2}
        >
          <Typography style={{ ...TypographyStyle, color: "white" }}>
            Tel: 07550757943
          </Typography>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
          }}
          item
          md={10}
        >
          {admin === "" && (
            <a href="login" style={{ textDecoration: "none" }}>
              <Typography
                style={{
                  ...navbarStyle,
                  backgroundColor: "#4CDBC4",
                  width: "150px",
                  color: "white",
                }}
              >
                Admin Login
              </Typography>
            </a>
          )}
          {admin !== "" && (
            <>
              {!window.location.href.includes("dashboard") && (
                <a href="dashboard" style={{ textDecoration: "none" }}>
                  <Typography
                    style={{
                      ...navbarStyle,
                      backgroundColor: "#4CDBC4",
                      width: "150px",
                      color: "white",
                    }}
                  >
                    Dashboard
                  </Typography>
                </a>
              )}
              <Typography
                onClick={() => logoutAdmin()}
                style={{
                  ...navbarStyle,
                  backgroundColor: "red",
                  width: "150px",
                  color: "white",
                }}
              >
                Admin Logout
              </Typography>
            </>
          )}
        </Grid>
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "auto", // Let the height adjust based on content
            flexDirection: "column", // Stack the items vertically on small screens
          }}
          m={3}
          item
          xs={12}
        >
          <Box display="flex" justifyContent="center" alignItems={"center"}>
            <Box>
              <img
                style={{
                  marginTop: "16px",
                  height: "148px",
                  width: "148px",
                  borderRadius: "50%",
                  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
                  objectFit: "cover",
                }}
                srcSet={logo}
                alt="Logo"
              />
            </Box>
            <Box>
              <Typography
                sx={{
                  ...navbarTitleStyle,
                  textAlign: "center", // Center the text on small screens
                  fontSize: "22px", // Adjust the font size for small screens
                  padding: "10px", // Add some padding for better spacing
                }}
              >
                IQ Tutors: 11 Plus, KS3 Science and GCSE Physics Tuition
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container display={"flex"} justifyContent="center" mb={2}>
        <a href="/" style={{ textDecoration: "none" }}>
          <Grid
            sx={{
              ...navbarStyle,
              backgroundColor: id === 1 ? "#4CDBC4" : "#FAC761",
            }}
            item
          >
            <Typography sx={TypographyStyle}>Home</Typography>
          </Grid>
        </a>
        <a href="/about" style={{ textDecoration: "none" }}>
          <Grid
            sx={{
              ...navbarStyle,
              backgroundColor: id === 2 ? "#4CDBC4" : "#FAC761",
            }}
            item
          >
            <Typography sx={TypographyStyle}>About Me</Typography>
          </Grid>
        </a>
        <a style={{ textDecoration: "none" }} href="/what-i-teach">
          <Grid
            sx={{
              ...navbarStyle,
              backgroundColor: id === 3 ? "#4CDBC4" : "#FAC761",
            }}
            item
          >
            <Typography sx={TypographyStyle}>What I Teach</Typography>
          </Grid>
        </a>
        <a href="/packages" style={{ textDecoration: "none" }}>
          <Grid
            sx={{
              ...navbarStyle,
              backgroundColor: id === 4 ? "#4CDBC4" : "#FAC761",
            }}
            item
          >
            <Typography sx={TypographyStyle}>Packages</Typography>
          </Grid>
        </a>
        <a href="student-area" style={{ textDecoration: "none" }}>
          <Grid
            sx={{
              ...navbarStyle,
              backgroundColor: id === 5 ? "#4CDBC4" : "#FAC761",
            }}
            item
          >
            <Typography sx={TypographyStyle}>Student Area </Typography>
          </Grid>
        </a>
        <a href="contact" style={{ textDecoration: "none" }}>
          <Grid
            sx={{
              ...navbarStyle,
              backgroundColor: id === 6 ? "#4CDBC4" : "#FAC761",
            }}
            item
          >
            <Typography sx={TypographyStyle}>Contact</Typography>
          </Grid>
        </a>
      </Grid>
    </>
  );
};
export default Navbar;
